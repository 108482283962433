import React from "react";

import { FaRegUser } from "@react-icons/all-files/fa/FaRegUser";

import { useUserStore } from "../../store/user";
import { logout } from "../../api/api";

import { removeUser } from "../../utils/cookies";

interface IProps {
    height: number;
}

export default function DeconnexionForm({ height }: IProps) {
    const { oUser, setUser } = useUserStore();

    const onLogout = () => {
        logout()
            .then((res) => {
                removeUser();
                setUser(null);
            })
            .catch((error) => {
                console.log("Log out error ->", error);
            });
    };

    return (
        <div
            className="tw-border-t-2 tw-border-t-black tw-text-primary tw-bg-white tw-h-[67vh] tw-border-b-2 tw-border-solid tw-border-b-[#67648A] sm:tw-h-[60vh]"
            style={{ height: height + "px", width: "auto" }}
        >
            <div className="tw-text-center tw-py-4 tw-pt-11">
                <div className="tw-flex tw-justify-center tw-pb-6">
                    <FaRegUser className="tw-w-[125px] tw-h-[125px]" />
                </div>
                <p
                    className="tw-text-md sm:tw-text-2xl tw-font-bold   tw-mb-3"
                    style={{}}
                >
                    Vous êtes connecté en tant que
                </p>
                <h4>{oUser?.name}</h4>
                <div className="tw-w-full tw-flex tw-justify-center">
                    <button
                        className="tw-btn tw-btn-sm tw-font-normal tw-mt-2 tw-w-full tw-text-xs sm:tw-w-48 sm:tw-text-sm lg:tw-px-4 tw-rounded-xl tw-text-white tw-font-bold tw-bg-secondary hover:tw-bg-secondary_hover"
                        onClick={onLogout}
                    >
                        Se déconnecter
                    </button>
                </div>
            </div>
        </div>
    );
}
